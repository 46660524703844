<template>
  <div v-if="isThumbnail" class="h-full mx-auto" :style="{ maxHeight: 'calc(100% - 2.5rem)'}">
    <img :src="block.embed.poster" :alt="block.title" class="w-full h-full object-cover" />
  </div>

  <div v-else :id="`player-${block.id}`" class="h-full">
    <turbo-frame 
      :id="`embed_${block.embed.id}`" 
      :src="`/embeds/${block.embed.id}`" 
      :class="youtubeWrapperClass"
      :style="youtubeStyle"
      data-provider="youtube"
      data-youtube-target="video"
      loading="lazy">
      <div :class="youtubeVideoClass" class="w-full h-full rounded-lg animate-pulse bg-gray-200"></div>
    </turbo-frame>
  </div>
</template>

<script setup>
import { toRef, onMounted, watch, defineProps } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'

const props = defineProps({
  block: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  },
})

const embedRef = toRef(props.block, 'embed')
const { youtubeWrapperClass, youtubeStyle, youtubeVideoClass } = useEmbedHelpers(embedRef)

watch(
  () => [
    props.block.embed.original_url,
    props.block.embed.start,
    props.block.embed.end,
  ],
  () => {
    if (!props.isThumbnail) {
      const videoEl = document.querySelector('[data-controller="video-player"]')
      videoEl.parentElement.src = videoEl.parentElement.src

    }
  }
)
</script>
