import { Controller } from '@hotwired/stimulus'
import Reveal from 'reveal.js'
import FsFx from 'reveal.js-fsfx'
import { hideAll } from 'tippy.js'

export default class extends Controller {
  static targets = ["innerSlide"]

  connect() {
    this.initReveal()
  }

  initReveal() {
    let deck = new Reveal({
      plugins: [FsFx],
      slideNumber: 'c/t',
      hash: true,
      fsfx: {
        auto: {
          position: {
            left: '20px',
            top: '20px',
            right: false
          }
        }
      }
    })

    deck.initialize({
      width: 1280,
      height: 720,
      // maxScale: 10.0
    })

    deck.on('ready', (event) => {
      setTimeout(() => {
        this.handleSlideScrolling(event.currentSlide)
      }, 100)
      this.resizeVideo()
      this.resizeTableQuestion()
      this.resizeSpark()
    })

    deck.on('slidechanged', (event) => {
      if (event.previousSlide) {
        this.resetVideos(event.previousSlide)
      }

      if (event.previousSlide) {
        this.resetSlideScrolling(event.previousSlide)
      }
      setTimeout(() => {
        this.handleSlideScrolling(event.currentSlide)
      }, 100)
      this.resizeVideo()
      this.resizeTableQuestion()
      this.resizeSpark()
    })
  }

  async resizeSpark() {
    const currentSlide = document.querySelector('section.present')

    if (!currentSlide.querySelector('[data-spark-slide]')) {
      return
    }
  }

  async resizeVideo() {
    const currentSlide = document.querySelector('section.present')
    if (!currentSlide.querySelector('[data-video]')) {
      return
    }

    const embed = currentSlide.querySelector('iframe')

    if (embed.src.includes('youtube.com')) {
      const videoId = embed.src.split('/embed/')[1].split('?')[0]

      const { height, width } = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}`)
        .then(res => res.json())

      const ratio = width / height

      const currentWidth = embed.offsetWidth

      currentSlide.style.top = 0
      embed.style.height = `${currentWidth / ratio}px`
      document.querySelector('.slides').style.height = `${currentWidth / ratio}px`
    }

  }

  resizeTableQuestion() {
    const currentSlide = document.querySelector('section.present')
    if (!currentSlide.querySelector('[data-type="sortable"]')) {
      return
    }

    const tableQuestion = currentSlide.querySelector('[data-type="sortable"]')

    // find the largest span within a data-label-cell item
    const spans = tableQuestion.querySelectorAll('[data-label-cell] span')

    // find span with largest width
    const largestSpan = [...spans].reduce((a, b) => a.offsetWidth > b.offsetWidth ? a : b)
    const cellStyle = window.getComputedStyle(largestSpan.parentElement)
    const cellPadding = parseInt(cellStyle.paddingLeft) + parseInt(cellStyle.paddingRight)

    // set width of label header to that width
    tableQuestion.querySelector('[data-label-header]').style.width = `${largestSpan.offsetWidth + cellPadding + 10}px`
  }

  resetSlideScrolling(slide) {
    if (slide.querySelector('[data-scrollable-container]')) {
      slide.querySelector('[data-scrollable-container]').classList.remove("overflow-y-auto", "overflow-x-hidden")
    } else {
      slide.classList.remove('scrollable-slide')
    }
  }

  handleSlideScrolling(slide) {
    const blocksContainer = slide.querySelector('[data-blocks]')
    const height = parseInt(slide.parentElement.style.height)

    let contentHeight = 0

    if (blocksContainer) {
      contentHeight = Array.from(blocksContainer.querySelectorAll('[data-block]')).reduce((a, b) => a + b.offsetHeight, 0)
    } else if (slide.querySelector("turbo-frame")) {
      contentHeight = Array.from(slide.querySelector("turbo-frame").children).reduce((a, b) => a + b.offsetHeight, 0)
    } else {
      contentHeight = Array.from(slide.children).reduce((a, b) => a + b.offsetHeight, 0)
    }

    if (contentHeight > height && !slide.querySelector("[data-provider='youtube']")) {
      // slide.classList.remove("overflow-hidden")
      // slide.classList.add("overflow-y-auto", "overflow-x-hidden")
      if (slide.querySelector('[data-scrollable-container]')) {
        slide.querySelector('[data-scrollable-container]').classList.add("overflow-y-auto", "overflow-x-hidden")
      } else {
        slide.style.height = height + 'px'
        slide.classList.add('scrollable-slide')
      }
    }
  }

  innerSlideTargetConnected(innerSlide) {
    this.handleSlideScrolling(innerSlide.closest("section"))
  }

  resetVideos(slide) {
    const videoEls = slide.querySelectorAll('[data-controller="video-player"]')
    videoEls.forEach((el) => { el.parentElement.src = el.parentElement.src })
  }
}