import { Controller } from '@hotwired/stimulus'
import Vlitejs from 'vlitejs'
import YouTube from 'vlitejs/providers/youtube.js'

Vlitejs.registerProvider('youtube', YouTube)

export default class extends Controller {
  static targets = ['player']

  static values = {
    poster: String,
    provider: String,
    start: Number,
    end: Number
  }

  connect () {
    this.init()
  }

  init () {
    if (!this.hasPlayerTarget) return

    this.player = new Vlitejs(this.playerTarget, this.options)
  }

  get options () {
    return {
      provider: ["youtube"],
      options: {
        progressBar: false,
        poster: this.posterValue,
        providerParams: {
          start: this.startValue,
          end: this.endValue,
          rel: 0,
        },
      },
      onReady: (player) => {
        // This resets the video after ended
        player.on("ended", () => {
          player.pause();
          player.seekTo(this.startValue)
        });
      },
    }
  }
}
